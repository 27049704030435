<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" @keyup.enter="createUser">
    <div class="flex justify-center sm:w-full lg:w-2/3 xl:w-3/5 xxl:w-2/5 sm:m-0 m-4">
      <vx-card v-if="!loadingData" class="w-full sm:w-1/2">
        <div slot="no-body" class="w-full">
          <div class="vx-row no-gutter">
            <!-- <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="register" class="mx-auto" />
            </div> -->
            <div class="w-full">
              <div v-if="!isGuide || isGuide.empty" class="p-8">
                <div class="vx-card__title">
                  <h4 class="mb-4 text-center">Account details</h4>
                  <p class="text-center">Please fill in some details about yourself before continuing</p>
                </div>
                <div class="clearfix">
                  <vs-input
                    v-model.trim="name"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="blur"
                    label-placeholder="First name"
                    name="name"
                    placeholder="First name"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{ errors.first('name') }}</span>

                  <vs-input
                    v-model.trim="surname"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="blur"
                    label-placeholder="Last name"
                    name="surname"
                    placeholder="Last name"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{ errors.first('surname') }}</span>

                  <vs-input
                    v-model="telephone"
                    v-validate="'required|alpha_dash|min:3'"
                    data-vv-validate-on="blur"
                    label-placeholder="Phone number"
                    name="telephone"
                    placeholder="Phone number"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{ errors.first('telephone') }}</span>

                  <div class="mt-6 flex items-center">
                    <vs-checkbox v-model="addToSenderListAccepted" class="flex items-center"></vs-checkbox>
                    <p class="flex items-center">I would like to receive emails about updates to Recruit a Guide</p>
                  </div>
                  <!-- <vs-button type="border" class="mt-6" @click="logout">Logout</vs-button> -->
                  <vs-button class="mt-6 w-full" :disabled="!validateForm" @click="createUser">Save</vs-button>
                  <p class="text-sm mt-3 text-center"
                    >I am not supposed to be here.
                    <span class="text-rag-green-dark cursor-pointer hover:underline" @click="logout">Logout</span></p
                  >
                </div>
              </div>
              <div v-else class="p-8">
                <div class="vx-card__title">
                  <h4 class="mb-4">Sorry!</h4>
                  <p
                    >This email is associated with a Recruit a Guide mobile app account. You <strong>cannot</strong> login into the Employer
                    Dashboard with an account that was created on the mobile app.</p
                  >
                  <br />
                  <p> Register an Employer account with a different email address.</p>
                  <vs-button type="border" class="mt-6 w-full" @click="logout">Back to login</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
export default {
  data() {
    return {
      name: '',
      surname: '',
      telephone: '',
      loadingData: false,
      email: '',
      password: '',
      phoneNumber: '',
      confirm_password: '',
      isTermsConditionAccepted: true,
      addToSenderListAccepted: true,
      isGuide: null,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.username != '' && this.telephone != '';
    },
  },
  async mounted() {
    this.$vs.loading({ type: 'corners' });
    this.loadingData = true;
    const employer = this.$store.state.employerModule.data;
    this.isGuide = await firebase.firestore().collection('users').where('userId', '==', employer.id).get();
    this.loadingData = false;
    if (!this.isGuide.empty) {
      await this.deleteEmployer(employer.id);
      this.$vs.loading.close();
    }
    this.$vs.loading.close();
  },
  methods: {
    async deleteEmployer(employerId) {
      await this.$store.dispatch('employerModule/closeDBChannel', { clearModule: true });
      await firebase.firestore().collection('employers').doc(employerId).delete();
      // getAuth().signOut();
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    async createUser() {
      this.$vs.loading({ type: 'corners' });
      const auth = getAuth();
      if (!this.validateForm) return false;

      const payload = {
        userDetails: {
          email: auth.currentUser.email,
          name: this.name,
          surname: this.surname,
          telephone: this.telephone,
          addToSGSenderList: this.addToSenderListAccepted,
        },
        cred: {
          user: auth.currentUser,
        },
        notify: this.$vs.notify,
      };

      await this.$store.dispatch('employerModule/createUser', payload);
      await this.$store.dispatch('employerModule/patch', this.$store.state.employerModule.data);
      this.$vs.loading.close();
      this.$router.push('/pages/dashboard');
    },
    notifyAlreadyLogedIn() {
      this.$vs.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      });
    },
  },
};
</script>
